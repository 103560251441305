import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 8; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/03-feb-24-1/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function ParikshaPeCharcha2024() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Pariksha Pe Charcha</h1>

        <p>
          The students of classes IX to XII of Chinmaya Vidyalaya tuned into the
          live telecast of the 7th edition of Prime Miniter’s Pariksha Pe
          Charcha.
          <br />
          <br />
          PM Modi highlighted the growing performance stress on children and the
          negative consequences of it. He highlighted the importance of
          competing with oneself rather than others.
          <br />
          <br />
          He also stressed the importance of a strong bond and trust between
          parents, children, and students and teachers. This bond can act as a
          support for students in difficult times.
          <br />
          <br />
          The Prime Minister also discussed the significance of good health and
          sleep, particularly during exam time.
          <br />
          <br />
          He also addressed exam-related concerns and shared tips on preparing
          for the upcoming exams. He also cautioned parents against excessive
          comparison.
          <br />
          <br />
          The session was very well-received and appreciated by the students.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
